<template>
  <div>
    <div v-if="sidebarVisible" class="backdrop" @click="toggleSidebar"></div>
    <div class="container-fluid bg-primary">
      <nav class="navbar px-md-4">
        <div class="navbar-left site-logo-container" :class="logoClasses">
          <NuxtLink
            id="nav-logo-link"
            :class="logoIsWide && 'd-none d-md-block'"
            :to="logoRoute"
            :aria-label="logoAriaLabel"
          >
            <img class="site-logo" :src="logoPath" :alt="''" role="presentation" />
          </NuxtLink>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import useSidebar from '@/hooks/sidebar';

export default defineComponent({
  props: {
    logoPath: {
      type: String,
      required: true,
    },
    logoRoute: {
      type: String,
      required: true,
    },
    logoAriaLabel: {
      type: String,
      required: true,
    },
    logoIsWide: {
      type: Boolean,
      default: false,
    },
    logoClasses: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { toggleSidebar, sidebarVisible } = useSidebar();

    return { toggleSidebar, sidebarVisible };
  },
});
</script>

<style lang="scss" scoped>
.site-logo {
  max-height: 70px;
}

.navbar {
  height: 48px;
}

.navbar-left,
.navbar-right {
  flex-grow: 1;
  flex-basis: 0;
}

.backdrop {
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;

  &.student {
    background-color: #000;
  }
  &.teacher {
    background-color: #000;
  }
}
</style>
