<template>
  <NavbarExternal
    v-if="pageType === 'career-pass'"
    :logoPath="logoPath"
    logoRoute="/"
    logoAriaLabel="Home"
  />
  <Navbar v-else logoPath="/icons/c-logo-student.svg" logoRoute="/" logoAriaLabel="Home" />
</template>

<script>
import { defineComponent, useRoute, computed } from '@nuxtjs/composition-api';
import Navbar from '@/components/layout/navigation/Navbar';
import NavbarExternal from '@/components/layout/navigation/NavbarExternal';

export default defineComponent({
  components: {
    Navbar,
    NavbarExternal,
  },
  setup() {
    const route = useRoute();

    const pageType = computed(() => {
      return ['career-pass-view'].includes(route.value.name) ? 'career-pass' : 'default';
    });

    const logoPath = computed(() => {
      return pageType.value === 'career-pass'
        ? '/images/passport/career-pass-share-top-logo.png'
        : '/icons/c-logo-student.svg';
    });

    return { logoPath, pageType };
  },
});
</script>

<style lang="scss" scoped></style>
