import { useStore, computed } from '@nuxtjs/composition-api';
import useAnalytics from '@/hooks/analyticsService';
import * as EVENT from '@/constants/analyticsEvent';

export default function useSidebar() {
  const store = useStore();
  const analytics = useAnalytics();

  const toggleSidebar = () => {
    if (!store.state.ui.showSidebar) {
      analytics.track(EVENT.CTX_LINK_CLICKED, {
        link_category: 'Menu Items',
        link_action: 'click',
        link_name: 'Left toggle sidebar',
      });
    }

    store.dispatch('ui/toggleSidebar');
  };

  const ensureSidebarVisible = () => {
    if (store.state.ui.showSidebar) {
      return;
    }

    toggleSidebar();
  };

  const ensureSidebarNotVisible = () => {
    if (!store.state.ui.showSidebar) {
      return;
    }

    toggleSidebar();
  };

  const sidebarVisible = computed(() => store.state.ui.showSidebar);

  return {
    toggleSidebar,
    sidebarVisible,
    ensureSidebarVisible,
    ensureSidebarNotVisible,
  };
}
